import React from "react";
import Layout from "../../components/layout";
import HeaderOne from "../../components/header/header-one";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import Resourcepapers from '../../components/resources/resource-papers'
import ComingSoon from "../Work/Coming-soon";
const Papers = () => {
  return (
    <Layout pageTitle="Papers">
      <HeaderOne />
      <StickyHeader />
      <section className="mhh-bg-image">
      <Resourcepapers/>
      <ComingSoon />
      </section>
      <Footer />
    </Layout>
  );
};
export default Papers